<template>
  <div>
    <WsOrder
      :orderLayerFields="$store.state.stone_model[_modelName].orderLayerFields"
      :getUrl="_getUrl"
      :patchUrl="_patchUrl"
    ></WsOrder>
  </div>
</template>

<script>
export default {
  computed: {
    _getUrl() {
      if (
        this.$store.state.stone_model[this._modelName].crudSetting.order &&
        this.$store.state.stone_model[this._modelName].crudSetting.order.getUrl
      ) {
        return this.$store.state.stone_model[this._modelName].crudSetting.order
          .getUrl;
      } else {
        return `/${this._urlModelName}/order`;
      }
    },
    _patchUrl() {
      if (
        this.$store.state.stone_model[this._modelName].crudSetting.order &&
        this.$store.state.stone_model[this._modelName].crudSetting.order
          .patchUrl
      ) {
        return this.$store.state.stone_model[this._modelName].crudSetting.order
          .patchUrl;
      } else {
        return `/${this._urlModelName}/order`;
      }
    },
    _modelName() {
      return this.$route.meta.modelName;
    },
    _urlModelName() {
      return this.$route.meta.urlModelName
        ? this.$route.meta.urlModelName
        : this._modelName;
    },
  },
  mounted() {},
};
</script>